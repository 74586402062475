import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReCaptcha from "react-recaptcha";

const Form = () => {
  return (
    <Fragment>
      <div id="crmWebToEntityForm">
        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />

        <form
          action="https://crm.zoho.com/crm/WebToCaseForm"
          name="WebToCases4355741000000272149"
          method="POST"
          encType="multipart/form-data"
          onSubmit={checkMandatory4355741000000272149}
          acceptCharset="UTF-8"
          className="form"
        >
          <input
            type="text"
            style={{ display: "none" }}
            name="xnQsjsdp"
            defaultValue="ea96ae1d23bd5074e470c9840795279bb3afeaf306ef90182fc263a05ceedf13"
          />
          <input type="hidden" name="zc_gad" id="zc_gad" defaultValue />
          <input
            type="text"
            style={{ display: "none" }}
            name="xmIwtLD"
            defaultValue="f2d0e4b81634c2d13f90fcc18a1ff83a14e99bd571362514b7a3fe4cbcddbbca"
          />
          <input
            type="text"
            style={{ display: "none" }}
            name="actionType"
            defaultValue="Q2FzZXM="
          />
          <input
            type="text"
            style={{ display: "none" }}
            name="returnURL"
            defaultValue="petcare.solutions/complete"
          />

          <TextField
            variant="filled"
            label="Subject of Claim"
            maxLength={300}
            name="Subject"
            required
          />
          <TextField
            variant="filled"
            label="First Name"
            maxLength={255}
            name="CASECF3"
            required
          />
          <TextField
            variant="filled"
            label="Last Name"
            maxLength={255}
            name="CASECF2"
            required
          />
          <TextField
            variant="filled"
            label="Email"
            maxLength={300}
            name="Email"
            required
          />
          <TextField
            variant="filled"
            label="Phone"
            maxLength={300}
            name="Phone"
            required
          />
          <TextField
            variant="filled"
            label="Street Address"
            maxLength={255}
            name="CASECF4"
          />
          <TextField
            variant="filled"
            label="City"
            maxLength={255}
            name="CASECF9"
          />
          <FormControl variant="filled" required>
            <InputLabel id="CASECF11">State</InputLabel>
            <Select name="CASECF11" labelId="CASECF11">
              <MenuItem value="Alabama">Alabama</MenuItem>
              <MenuItem value="Alaska">Alaska</MenuItem>
              <MenuItem value="Arizona">Arizona</MenuItem>
              <MenuItem value="Arkansas">Arkansas</MenuItem>
              <MenuItem value="California">California</MenuItem>
              <MenuItem value="Colorado">Colorado</MenuItem>
              <MenuItem value="Connecticut">Connecticut</MenuItem>
              <MenuItem value="Delaware">Delaware</MenuItem>
              <MenuItem value="Florida">Florida</MenuItem>
              <MenuItem value="Georgia">Georgia</MenuItem>
              <MenuItem value="Hawaii">Hawaii</MenuItem>
              <MenuItem value="Idaho">Idaho</MenuItem>
              <MenuItem value="Illinois">Illinois</MenuItem>
              <MenuItem value="Indiana">Indiana</MenuItem>
              <MenuItem value="Iowa">Iowa</MenuItem>
              <MenuItem value="Kansas">Kansas</MenuItem>
              <MenuItem value="Kentucky">Kentucky</MenuItem>
              <MenuItem value="Louisiana">Louisiana</MenuItem>
              <MenuItem value="Maine">Maine</MenuItem>
              <MenuItem value="Maryland">Maryland</MenuItem>
              <MenuItem value="Massachusetts">Massachusetts</MenuItem>
              <MenuItem value="Michigan">Michigan</MenuItem>
              <MenuItem value="Minnesota">Minnesota</MenuItem>
              <MenuItem value="Mississippi">Mississippi</MenuItem>
              <MenuItem value="Missouri">Missouri</MenuItem>
              <MenuItem value="Montana">Montana</MenuItem>
              <MenuItem value="Nebraska">Nebraska</MenuItem>
              <MenuItem value="Nevada">Nevada</MenuItem>
              <MenuItem value="New Hampshire">New Hampshire</MenuItem>
              <MenuItem value="New Jersey">New Jersey</MenuItem>
              <MenuItem value="New Mexico">New Mexico</MenuItem>
              <MenuItem value="New York">New York</MenuItem>
              <MenuItem value="North Carolina">North Carolina</MenuItem>
              <MenuItem value="North Dakota">North Dakota</MenuItem>
              <MenuItem value="Ohio">Ohio</MenuItem>
              <MenuItem value="Oklahoma">Oklahoma</MenuItem>
              <MenuItem value="Oregon">Oregon</MenuItem>
              <MenuItem value="Pennsylvania">Pennsylvania</MenuItem>
              <MenuItem value="Rhode Island">Rhode Island</MenuItem>
              <MenuItem value="South Carolina">South Carolina</MenuItem>
              <MenuItem value="South Dakota">South Dakota</MenuItem>
              <MenuItem value="Tennessee">Tennessee</MenuItem>
              <MenuItem value="Texas">Texas</MenuItem>
              <MenuItem value="Utah">Utah</MenuItem>
              <MenuItem value="Vermont">Vermont</MenuItem>
              <MenuItem value="Virginia">Virginia</MenuItem>
              <MenuItem value="Washington">Washington</MenuItem>
              <MenuItem value="West Virginia">West Virginia</MenuItem>
              <MenuItem value="Wisconsin">Wisconsin</MenuItem>
              <MenuItem value="Wyoming">Wyoming</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="filled"
            label="Zip Code"
            maxLength={255}
            name="CASECF10"
          />
          <TextField
            variant="filled"
            label="Type of Pet"
            maxLength={255}
            name="CASECF6"
          />
          <TextField
            variant="filled"
            label="Pet Purchased Location"
            maxLength={255}
            name="CASECF1"
            required
          />

          <FormControl variant="filled" required>
            <InputLabel>Care Origin</InputLabel>
            <Select defaultValue="Web" name="Case Origin">
              <MenuItem value="Web">Web</MenuItem>
            </Select>
          </FormControl>

          <TextField
            className="textarea"
            name="CASECF7"
            placeholder="Brief description of question or concern"
            multiline={true}
            rows={4}
            rowsMax={6}
            variant="filled"
          />
          <span className="contact__optional-text">
            <strong>OPTIONAL:</strong> Please upload any documentation relevant
            to your concern or question.<br></br>Accepted file types: pdf, doc,
            docx, gif, jpeg, jpg, png, rtf, txt |{" "}
            <strong>LIMIT: 3 FILES MAX (20MB)</strong>
          </span>

          <input
            className="form__upload-file"
            type="file"
            name="theFile"
            id="theFile"
            multiple
          />
          <div></div>
          <ReCaptcha
            sitekey="6LdNzNoUAAAAAE8_C37KK7f4VOMI8Fmvz7zx7PgO"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
          <button
            id="formsubmit"
            type="submit"
            defaultValue="Submit"
            className="form__submit-button"
          >
            SEND FORM
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default Form;

var mndFileds = new Array(
  "Case Origin",
  "Subject",
  "CASECF1",
  "CASECF3",
  "Email",
  "CASECF2",
  "Phone"
);
var fldLangVal = new Array(
  "Case Origin",
  "Subject of Claim",
  "Pet Purchased Location",
  "First Name",
  "Email",
  "Last Name",
  "Phone"
);
var name = "";
var email = "";

const callback = function() {
  var submitbutton = document.getElementById("formsubmit");
  submitbutton.setAttribute("disabled", true);
};

const verifyCallback = function(response) {
  if (response) {
    document.getElementById("formsubmit").removeAttribute("disabled");
  }
};

const checkMandatory4355741000000272149 = () => {
  for (var i = 0; i < mndFileds.length; i++) {
    var fieldObj =
      document.forms["WebToCases4355741000000272149"][mndFileds[i]];
    if (fieldObj) {
      if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
        if (fieldObj.type == "file") {
          alert("Please select a file to upload.");
          fieldObj.focus();
          return false;
        }
        alert(fldLangVal[i] + " cannot be empty.");
        fieldObj.focus();
        return false;
      } else if (fieldObj.nodeName == "SELECT") {
        if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
          alert(fldLangVal[i] + " cannot be none.");
          fieldObj.focus();
          return false;
        }
      } else if (fieldObj.type == "checkbox") {
        if (fieldObj.checked == false) {
          alert("Please accept  " + fldLangVal[i]);
          fieldObj.focus();
          return false;
        }
      }
      try {
        if (fieldObj.name == "Last Name") {
          name = fieldObj.value;
        }
      } catch (e) {}
    }
    return validateFileUpload();
  }

  document.getElementById("formsubmit").disabled = true;
};

function validateFileUpload() {
  var uploadedFiles = document.getElementById("theFile");
  var totalFileSize = 0;
  if (uploadedFiles.files.length > 5) {
    alert("You can upload a maximum of three files at a time.");
    return false;
  }
  if ("files" in uploadedFiles) {
    if (uploadedFiles.files.length != 0) {
      for (var i = 0; i < uploadedFiles.files.length; i++) {
        var file = uploadedFiles.files[i];
        if ("size" in file) {
          totalFileSize = totalFileSize + file.size;
        }
      }
      if (totalFileSize > 20971520) {
        alert("Total file(s) size should not exceed 20MB.");
        return false;
      }
    }
  }
}
