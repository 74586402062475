import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from 'components/Container';
import '../assets/stylesheets/styles.scss';
import doggy from '../assets/images/doggy.png';
import Form from '../components/Form';
import leftWave from '../assets/images/left-wave.svg';
import rightSmallWave from '../assets/images/right-small-wave.svg';
import rightBigWave from '../assets/images/right-big-wave.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const IndexPage = () => {
    return (
        <Fragment>
            <Helmet>
                <script src="https://www.google.com/recaptcha/api.js"></script>
                <title>Petcare Solutions</title>
            </Helmet>

            <div className="hero">
                <Header />
                <Container>
                    <div className="hero__container">
                        <div className="hero__img">
                            <img className="hero__doggy" src={doggy}></img>
                            <img
                                className="hero__left-wave"
                                src={leftWave}
                            ></img>
                        </div>
                        <div className="hero__content">
                            <h1 className="hero__title">
                                WELCOME TO<br></br>PETCARE SOLUTIONS
                            </h1>
                            <p className="hero__description">
                                Please use the form below to submit your{' '}
                                <br></br>question or concern. We will respond to
                                your<br></br>submission within one business day.
                            </p>

                            <AnchorLink
                                href="#form-anchor"
                                className="hero__cta"
                                offset="100"
                            >
                                SUBMIT YOUR CLAIM
                            </AnchorLink>

                            <img
                                className="hero__right-small-wave"
                                src={rightSmallWave}
                            ></img>
                            <img
                                className="hero__right-big-wave"
                                src={rightBigWave}
                            ></img>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="mobile-section">
                <div className="cta-container">
                    <AnchorLink
                        href="#form-anchor"
                        offset="100"
                        className="hero__cta"
                    >
                        SUBMIT YOUR CLAIM
                    </AnchorLink>
                </div>

                <h1 className="hero__title">
                    WELCOME TO<br></br>PETCARE SOLUTIONS
                </h1>
                <p className="hero__description">
                    Please use the form below to submit your question or
                    concern. We will respond to your submission within one
                    business day.
                </p>
                <div className="divider-mobile"></div>
            </div>
            <Container>
                <section className="information">
                    <span className="information__hours">
                        Our business hours (Eastern Time Zone) are: <br></br>
                        Monday – Sunday 10:00 AM – 7:00 PM EST.
                    </span>
                    <span className="information__phone">
                        <span className="information__urgency">
                            URGENCIES PLEASE CALL US
                        </span>
                        1-800-513-8570
                    </span>
                </section>
                <div className="divider"></div>
            </Container>
            <Container>
                <section className="contact" id="form-anchor">
                    <div className="contact__form">
                        <Form />
                    </div>
                    <div className="contact__info">
                        <span className="contact__urgency">
                            HOW CAN WE HELP?
                        </span>
                        <p className="contact__message">
                            Petcare.solutions provides you with the answers you
                            need for you and your pet.
                            <br></br>
                            <br></br>
                            Our company assists you with any pet-related issues
                            that require non-life threatening help by keeping
                            detailed, up-to-date records, diagnoses, warranty
                            questions, and more.We provide a multi-faceted
                            approach to our work to help you maintain a lifelong
                            friendship with your pet.
                        </p>
                    </div>
                </section>
                <div className="divider"></div>
                <section className="important">
                    <span className="important__urgency">IMPORTANT:</span>
                    <p className="important__message">
                        Petcare.solutions does not provide warranties or
                        underwriting, and it does not serve authoritative
                        measures over merchant claims decisions. Our company
                        mainly serves to bridge merchant-to-customer
                        communications. Because this message contains private
                        information, it is protected by the merchant and/or its
                        retail customer(s), and is only directed for the
                        recipient(s) specified. If you receive this message, and
                        are not the intended recipient as stated above, please
                        contact Petcare.solutions as soon as possible, and
                        delete this message to prevent the future release of
                        sensitive information.
                    </p>
                </section>

                <div className="spacer"></div>
            </Container>

            <Footer />
        </Fragment>
    );
};

export default IndexPage;
